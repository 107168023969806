@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /*background-color: blanchedalmond;*/
    font-size: 14px;
    font-family: monospace;
    color: #333;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 460px;
    height: 280px;
    padding: 12px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #999;
    background-color: #fff;
    transition: box-shadow 500ms;
}
.content:hover {
    box-shadow: 1px 4px 4px #999;
}

.left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.left::after {
    content: '';
    position: absolute;
    right: 0px;
    width: 1px;
    height: 120px;
    background-color: #eee;
}
.left__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #eee;
    cursor: pointer;
}
.ts-rotate-360 {
    transition: all 0.8s;
    transform: rotateZ(360deg);
}
/*.left__avatar img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
.left__name {
    margin-top: 12px;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.right {
    flex: 1;
    padding-left: 12px;
    overflow: hidden;
}
.right__title {
    font-size: 16px;
    font-weight: bold;
}
.right__title__other {
    text-decoration-line: line-through;
}
.right__content ul {
    padding-left: 12px;
}

.icp-hint {
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: cadetblue;
}



.wheat-waves {
    background: linear-gradient(
            300deg,
            #f9d423 0%,
            #f39c12 30%,
            #e74c3c 60%,
            #c0392b 100%
    );
    background-size: 200% 200%;
    animation: moveWaves 10s linear infinite;
}
@keyframes moveWaves {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.rapeseed-field {
    background: linear-gradient(
            to bottom,
            #ffff9f 0%,
            #c8e079 50%,
            #a0c957 100%
    );
    background-size: 200% 200%;
    animation: changeField 10s ease-in-out infinite alternate;
}

@keyframes changeField {
    0%, 100% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
}




*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[102px\] {
  bottom: 102px;
}

.right-2 {
  right: .5rem;
}

.right-6 {
  right: 1.5rem;
}

.right-\[48px\] {
  right: 48px;
}

.top-2 {
  top: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.size-\[16px\] {
  width: 16px;
  height: 16px;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[52px\] {
  height: 52px;
}

.h-full {
  height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-3 {
  width: .75rem;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[62px\] {
  width: 62px;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-\[\#5288f9\] {
  --tw-border-opacity: 1;
  border-color: rgb(82 136 249 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.bg-\[\#5288f9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(82 136 249 / var(--tw-bg-opacity));
}

.bg-\[5288f9\] {
  background-color: 5288f9;
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.pt-3 {
  padding-top: .75rem;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html, body {
  box-sizing: border-box;
  color: #333;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 14px;
}

body {
  justify-content: center;
  align-items: center;
  display: flex;
}

.content {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  width: 460px;
  height: 280px;
  padding: 12px;
  transition: box-shadow .5s;
  display: flex;
  box-shadow: 2px 2px 4px #999;
}

.content:hover {
  box-shadow: 1px 4px 4px #999;
}

.left {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  display: flex;
  position: relative;
}

.left:after {
  content: "";
  background-color: #eee;
  width: 1px;
  height: 120px;
  position: absolute;
  right: 0;
}

.left__avatar {
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.ts-rotate-360 {
  transition: all .8s;
  transform: rotateZ(360deg);
}

.left__name {
  color: #666;
  text-align: center;
  margin-top: 12px;
  font-size: 12px;
}

.right {
  flex: 1;
  padding-left: 12px;
  overflow: hidden;
}

.right__title {
  font-size: 16px;
  font-weight: bold;
}

.right__title__other {
  text-decoration-line: line-through;
}

.right__content ul {
  padding-left: 12px;
}

.icp-hint {
  color: #5f9ea0;
  font-size: 12px;
  position: absolute;
  bottom: 0;
}

.wheat-waves {
  background: linear-gradient(300deg, #f9d423 0%, #f39c12 30%, #e74c3c 60%, #c0392b 100%) 0 0 / 200% 200%;
  animation: 10s linear infinite moveWaves;
}

@keyframes moveWaves {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.rapeseed-field {
  background: linear-gradient(#ffff9f 0%, #c8e079 50%, #a0c957 100%) 0 0 / 200% 200%;
  animation: 10s ease-in-out infinite alternate changeField;
}

@keyframes changeField {
  0%, 100% {
    background-position: 50% 0;
  }

  50% {
    background-position: 50% 100%;
  }
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
/*# sourceMappingURL=index.af2c1ad5.css.map */
